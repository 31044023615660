import React from 'react'
import { graphql } from 'gatsby'
import { Container } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import ModalContact from '@solid-ui-blocks/Modal/Block01'
import Header from '@solid-ui-blocks/Header/Block01'
import Hero from '@solid-ui-blocks/Hero/Block02'
import CourseDetails from '@solid-ui-blocks/Features/Block06'
import CourseIntro from '@solid-ui-blocks/FeaturesWithPhoto/Block01'
import CourseOverview from '@solid-ui-blocks/Faq/Block02'
import CourseBlock from '@solid-ui-blocks/Stats/Block01'
import CTA from '@solid-ui-blocks/Stats/Block01'
import CTAContact from '@solid-ui-blocks/Hero/Block03'
import OrderNow from '@solid-ui-blocks/Hero/Block03'
import ChangeItUpRadio from '@solid-ui-blocks/Gallery/Block02'
import Weeks from '@solid-ui-blocks/Features/Block04'
import Footer from '@solid-ui-blocks/Footer/Block01'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import theme from './_theme'
import styles from './_styles'

const IndexPage = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    <Layout theme={theme} {...props}>
      <Seo title='Paula Shaw' />
      {/* Modals */}     
      <ModalContact content={content['contact']} />
      
      {/* Blocks */}
      <Header content={content['header']} />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='1' />
      <Container variant='full' sx={styles.heroContainer}>
        <Hero content={content['hero']} /> 
        <Divider space='2' />
        <Hero content={content['hero-button']} />
      </Container>
      <Divider space='5' />
      <CourseDetails content={content['course-details']} />
      <Divider space='4' />      
      <CourseIntro content={content['course-intro']}  reverse/>
      <Divider space='5' />
      <CourseBlock content={content['course-block']} />
      <Divider space='4' />      
      <Container variant='wide' sx={styles.featuresContainer}>
        <CourseOverview content={content['course-overview']} />
        <Divider space='1' />
        <Weeks content={content['weeks']} />
      </Container>  

      <Divider space='3' />
      <CTA content={content['CTA']} />
      <Divider space='3' />
      <CTAContact content={content['CTA-contact']} />
      <Divider space='4' />   
      <Container variant='wide' sx={styles.featuresContainer}>
          <ChangeItUpRadio content={content['change-it-up']} />  
      </Container>  
      <Divider space='3' />
      <Footer content={content['footer']} />
    </Layout>
  )
}

export const query = graphql`
  query indexBlockContent {
    allBlockContent(filter: { page: { in: ["site", "shared"] } }) {
      nodes {
        ...BlockContent
      }
    }
  }
`
export default IndexPage
