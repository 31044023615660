import React from 'react'
import { getImage } from 'gatsby-plugin-image'
import { Container, Flex, Box, css } from 'theme-ui'
import Reveal from '@solid-ui-components/Reveal'
import Divider from '@solid-ui-components/Divider'
import ContentContainer from '@solid-ui-components/ContentContainer'
import ContentText from '@solid-ui-components/ContentText'
import ContentImages from '@solid-ui-components/ContentImages'
import ContentButtons from '@solid-ui-components/ContentButtons'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'

const styles = {
  listItem: {
    flexBasis: [`2/2`, `2/2`],
    p: 3
  }
}

const GalleryBlock02 = ({ content: { text, buttons, collection } }) => (
  <Container>
    <Box sx={{ textAlign: `center` }}>
      <ContentText content={text} />
     
    </Box>
    {collection && text && <Divider />}
    <Flex sx={{ flexWrap: `wrap` }} m={-2}>
      {collection?.map(({ container, text, images }, index) => (
        <Reveal
          key={`item-${index}`}
          effect='fadeInGrow'
          threshold={0.2}
          delay={0.15 * (index > 2 ? index - 2 : index + 1)}
          css={css(styles.listItem)}
        >
          <ContentContainer
            content={container}
            variant='cards.interactive'
            sx={{
              height: `full`,
              overflow: `hidden`,
              display: `block`,
              maxWidth: getImage(images?.[0].src)?.width,
              mx: `auto`
              
            }}
          >
            <ContentImages
              content={{ images }}
              imagePosition='center'
              threshold={0.1}
            />    
            
          </ContentContainer>
        </Reveal>
      ))}
    </Flex>
    <Box sx={{ textAlign: `right` }}>  
      <Divider space={2} />    
      <ContentButtons content={buttons} />
    </Box>
  </Container>
)

export default WithDefaultContent(GalleryBlock02)
